// Shrink Navbar on scroll
window.onscroll = function () {
  scrollFunction();
};
function scrollFunction() {
  var navbar = document.getElementById("navbar")
  if (navbar) {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.getElementById("navbar").classList.remove('is-spaced');
    } else {
      document.getElementById("navbar").classList.add('is-spaced');
    }
  }
}