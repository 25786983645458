// Select all text in an form field when clicked
// simply add the class .select-all-text to the input
// Should work for textarea too although untested
// ref: https://stackoverflow.com/questions/4067469/selecting-all-text-in-html-text-input-when-clicked

document.addEventListener('turbolinks:load', function () {

  // get all inputs/textareas that have class .select-all-text
  // for each
  // add onclick event listener
  var selectAllInputs = document.getElementsByClassName('select-all-text');

  if (selectAllInputs) {
    for (var i = 0; i < selectAllInputs.length; i++) {
      selectAllInputs[i].addEventListener("click", function() {
        this.setSelectionRange(0, this.value.length);
      });
    }
  }

});