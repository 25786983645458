document.addEventListener("turbolinks:load", () => {
  
  var inviteBackgrounds = document.getElementById("invite-backgrounds")
  var backgroundImageContainer = document.getElementById("background-image-container");
  var whiteContentArea = document.getElementById("white-content-area");
  
  if (inviteBackgrounds && backgroundImageContainer && whiteContentArea) {
    var inviteBackgroundRadios = inviteBackgrounds.getElementsByClassName("invite-background-label-radio");
    for (var i=0, iLen=inviteBackgroundRadios.length; i<iLen; i++) {
      inviteBackgroundRadios[i].onclick = function() {
        // update style attribute to set new background image
        backgroundImageContainer.style.cssText = "background: url(" + this.dataset.imagePath + "); background-size: 480px 480px; background-repeat: repeat;";
        // update opacity of white content area
        whiteContentArea.style.cssText = "background-color: rgba(255, 255, 255, " + this.dataset.imageOpacity + ");";
      };
    }
  }

});