
function hideCharityButton() {
  var charityButtonField = document.getElementById("add-charity-button-field");
  if (charityButtonField) { charityButtonField.classList.add('is-hidden'); }
}

function showCharityButton() {
  var charityButtonField = document.getElementById("add-charity-button-field");
  if (charityButtonField) { charityButtonField.classList.remove('is-hidden'); }
}

function hideCharitySelectField() {
  var charitySelectField = document.getElementById("charity-select-field");
  if (charitySelectField) { charitySelectField.classList.add('is-hidden'); }
}

function showCharitySelectField() {
  var charitySelectField = document.getElementById("charity-select-field");
  if (charitySelectField) { charitySelectField.classList.remove('is-hidden'); }
}

function resetCharitySelect() {
  var charitySelect = document.getElementById("event_charity");
  if (charitySelect) { charitySelect.selectedIndex = 0; }
}

document.addEventListener("turbolinks:load", () => {

  var charityButton = document.getElementById("add-charity-button");
  if (charityButton) {
    charityButton.addEventListener("click", function(event) {
      event.preventDefault();
      hideCharityButton();
      showCharitySelectField();
    });
  }

  var removeCharityLink = document.getElementById("remove-charity");
  if (removeCharityLink) {
    removeCharityLink.addEventListener("click", function(event) {
      event.preventDefault();
      hideCharitySelectField();
      showCharityButton();
      resetCharitySelect();
    });
  }

});