import flatpickr from "flatpickr";
// require("flatpickr/dist/flatpickr.css")
require("flatpickr/dist/flatpickr.min.css")

flatpickr.l10ns.default.firstDayOfWeek = 1; // Monday
document.addEventListener("turbolinks:load", () => {
  var today = new Date();
  flatpickr("[data-behaviour='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    altInputClass: "input altFlatpickr",
    dateFormat: "Y-m-d",
    minDate: today,
  })
})