// Toggles address inputs based on user clicks and when the address lookup populates the address fields

// Behaviour
// default: 
//  - if address inputs are blank: hide address inputs, show search
//  - if address inputs contain values: show address inputs, hide search
// if click enter manually, clear all address inputs, unhide all address inputs, hide search-input
// if click search address, clear all address inputs, hide address inputs, show search-input

// NB. The bulma native class to hide a field is `is-hidden`

// TODO: only run this script on relevant pages (to avoid any potential conflicts RE: id names etc)?

// NB. Use 'WR5 3DA' (Loqate address) for testing as it doesn't deduct credits

function hideSearchInputField() {
  var searchInputField = document.getElementById("search-input-field");
  if (searchInputField) { searchInputField.classList.add('is-hidden'); }
}

function showSearchInputField() {
  var searchInputField = document.getElementById("search-input-field");
  if (searchInputField) { searchInputField.classList.remove('is-hidden'); }
}

function hideAddressFields() {
  var addressFields = document.getElementById("address-fields");
  if (addressFields) { addressFields.classList.add('is-hidden'); }
}

function showAddressFields() {
  var addressFields = document.getElementById("address-fields");
  if (addressFields) { addressFields.classList.remove('is-hidden'); }
}

function clearAddressInputs() {
  document.getElementById('address_line_1').value = '';
  document.getElementById('address_line_2').value = '';
  document.getElementById('address_city').value = '';
  document.getElementById('address_post_code').value = '';
}

function clearSearchInput() {
  document.getElementById('search-input').value = '';
}

function areAddressInputsEmpty() {
  // return true if all inputs are empty
  // otherwise return false
  var addressLine1 = document.getElementById('address_line_1');
  var addressLine2 = document.getElementById('address_line_2');
  var city = document.getElementById('address_city');
  var postCode = document.getElementById('address_post_code');
  if (addressLine1 && addressLine1.value == '' &&
      addressLine2 && addressLine2.value == '' &&
      city && city.value == '' &&
      postCode && postCode.value == '' ) {
    return true;
  } else {
    return false;
  }
}

function removeAddressValidationStyles() {
  // remove is-danger class from each input
  document.getElementById('address_line_1').classList.remove('is-danger');
  document.getElementById('address_line_2').classList.remove('is-danger');
  document.getElementById('address_city').classList.remove('is-danger');
  document.getElementById('address_post_code').classList.remove('is-danger');
  // remove <p class="help is-danger"> element from each address input
  var addressLine1Help = document.getElementById('address_line_1_help');
  if (addressLine1Help) { addressLine1Help.remove(); }
  var addressLine2Help = document.getElementById('address_line_2_help');
  if (addressLine2Help) { // use 'if' because line 2 is optional so won't show error text for 'present'
    addressLine2Help.remove();
  }
  var cityHelp = document.getElementById('address_city_help');
  if (cityHelp) { cityHelp.remove(); }
  var postCodeHelp = document.getElementById('address_post_code_help');
  if (postCodeHelp) { postCodeHelp.remove(); }
}

function hideOrSearchAddress() {
  var searchAddress = document.getElementById("search-address-para");
  if (searchAddress) { searchAddress.classList.add('is-hidden'); }
}

function hideAddressTestingMessage() {
  var addressLookupTestingMessage = document.getElementById("address-lookup-testing-message");
  if (addressLookupTestingMessage) { addressLookupTestingMessage.classList.add('is-hidden'); }
}

function physicalEvent() {
  var virtual = document.querySelector('input[name="event[virtual]"]:checked')
  if (virtual) {
    if (virtual.value == "1") {
      return false;
    } else if (virtual.value == "0") {
      return true;
    }
  }
}

function setDefaultAddressView() {
  if (areAddressInputsEmpty()) {
    hideAddressFields();
    showSearchInputField();
  } else {
    showAddressFields();
    hideSearchInputField();
  }
}

// Must wrap all event listeners in "turbolinks:load"
// otherwise they will try to run before the page loads
// NB. "turbolinks:load" is effectively a proxy for DOMContentLoaded
document.addEventListener("turbolinks:load", function () {

  // only set default view if virtual is false
  // (otherwise address fields show when virtual is selected)
  if (physicalEvent()) {
    setDefaultAddressView();
  }
  // When 'manual' link is clicked: Hide search and show address fields 
  var manualAddress = document.getElementById("manual-address");
  if (manualAddress) {
    manualAddress.addEventListener("click", function(event){
      event.preventDefault(); // stops actual click event so it doesn't redirect
      clearAddressInputs();
      hideSearchInputField();
      showAddressFields();
    });
  } 
  // When 'search' link is clicked: Show search and hide address fields 
  var searchAddress = document.getElementById("search-address");
  if (searchAddress) {
    searchAddress.addEventListener("click", function(event){
      event.preventDefault();
      clearSearchInput();
      showSearchInputField();
      hideAddressFields();
    });
  }
  // When address lookup populates inputs: Hide search and show address fields
  // Use Loqate supplied event listener as the normal input/change js listeners
  // do not detect input changes made via js
  // https://www.loqate.com/resources/support/setup-guides/quick/#advanced
  // https://www.loqate.com/resources/support/setup-guides/advanced-setup-guide/#events_listeners
  if (pca) {
    // Turn off Browser Autofill https://www.loqate.com/resources/support/setup-guides/quick/#browser_autofill
    pca.on("options", function(type, key, options) {
      options.suppressAutocomplete = false;
    });
    pca.on("load", function(type, id, control) {
      control.listen("populate", function(address) {
        removeAddressValidationStyles(); // in case user submitted form without searching first
        hideSearchInputField();
        showAddressFields();
      });
      control.listen("error", function() {
        // hack to prevent a Loqate error from blocking a user from completing the form
        // eg. Run out of credit
        // currently not super graceful and could be done better (because it only acts once it tries to search)
        hideOrSearchAddress();
        hideSearchInputField();
        showAddressFields();
      });
    });
  }

  // Hide testing message so users on staging can see what the page looks like on production
  var addressLookupTestingMessage = document.getElementById("address-lookup-testing-message");
  if (addressLookupTestingMessage) {
    addressLookupTestingMessage.addEventListener("click", function(event) {
      event.preventDefault();
      hideAddressTestingMessage();
    })
  }

  // if virtual event, hide address search & fields and update description and other details placeholders
  // if phsyical event, show search/address fields and revert description and other details placeholders
  var virtualRadios = document.getElementsByName('event[virtual]')
  if (virtualRadios) {
    for(var i = 0, max = virtualRadios.length; i < max; i++) {
      virtualRadios[i].onclick = function() {
        if (this.value == "1") {
          hideSearchInputField();
          hideAddressFields();
          // update Description placeholder
          var descriptionTextArea = document.getElementById('event_description');
          if (descriptionTextArea) { 
            descriptionTextArea.placeholder = "Eg. Join us on Zoom to celebrate and sing happy birthday!"
          }
          // update Other details placeholder
          var otherDetailsTextArea = document.getElementById('event_other_details');
          if (otherDetailsTextArea) { 
            otherDetailsTextArea.placeholder = "Eg. Link to video call, wear a funny hat!..."
          }
        } else if (this.value == "0") {
          setDefaultAddressView();
          // TODO: DRY this pecause the placehodlers are set in Rails too
          // revert Description placeholder
          var descriptionTextArea = document.getElementById('event_description');
          if (descriptionTextArea) { 
            descriptionTextArea.placeholder = "Eg. Join us to celebrate with lots of games & cake!"
          }
          // revert Other details placeholder
          var otherDetailsTextArea = document.getElementById('event_other_details');
          if (otherDetailsTextArea) { 
            otherDetailsTextArea.placeholder = "Eg. Direction & parking tips, bring towel..."
          }
        }
      }
    }
  }

});