// When user changes the Child selected in the dropdown, this script
// updates the 'Edit child' link to ensure the edit modal opens for the correct child

document.addEventListener("turbolinks:load", function () {

  var childSelect = document.getElementById('event_child_id');
  var editChild = document.getElementById('edit-child-link');

  if (childSelect && editChild) {
    childSelect.addEventListener("change", function() {
      editChild.href = "/user/children/" + childSelect.value + "/edit"; // eg. "/user/children/4/edit"
    });
  }

});