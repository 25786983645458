/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// check this file is loading by uncommenting below and checking the browser console
// console.log('Hello World from Webpacker')

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels") // ActionCable

// import custom js files from app/javascript/src
import '../src/navburger-bulma.js';
import '../src/address-lookup-toggle.js';
import '../src/contribution-option-toggles.js';
import '../src/event-custom-type-toggle.js';
import '../src/modal-open-close.js';
import '../src/shrink-navbar-on-scroll.js';
import '../src/choose-gifting.js';
import '../src/clipboard.js';
import '../src/select-all-text.js';
import '../src/tooltips.js';
import '../src/flatpickr-calendar.js';
import '../src/event-update-edit-child-link.js';
import '../src/invite-background-selector.js';
import '../src/event-charity-toggle.js';
import '../src/rsvp-gifting-tabs-toggle.js';
import '../src/turbolinks-fix-sroll.js';
import '../src/swiper.js';