

document.addEventListener("turbolinks:load", () => {

  // on click
  // ref: https://stackoverflow.com/questions/19655189/javascript-click-event-listener-on-class
  // remove has-text-primary from all icons and add it to clicked icon
  // hide all images, and show clicked image

  var circleIcons = document.getElementsByClassName("circle-icons");
  var giftingScreenshots = document.getElementsByClassName("gifting-screenshot");

  if (circleIcons && giftingScreenshots) {
    for (var i = 0; i < circleIcons.length; i++) {
      circleIcons[i].addEventListener("click", function(event) {
        event.preventDefault();
        for (var j = 0; j < circleIcons.length; j++) {
          circleIcons[j].classList.remove("has-text-primary");
        }
        this.classList.add("has-text-primary");

        for (var k = 0; k < giftingScreenshots.length; k++) {
          giftingScreenshots[k].classList.add("is-hidden");
        }
        var screenshot = document.getElementById(this.id + "-screenshot")
        screenshot.classList.remove("is-hidden");
      });
    }
  }

});