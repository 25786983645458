

function hideContributionOptionOther1() {
  var contributionOptionOther1 = document.getElementById("contribution-option-other-1");
  if (contributionOptionOther1) { contributionOptionOther1.classList.add('is-hidden'); }
}

function showContributionOptionOther1() {
  var contributionOptionOther1 = document.getElementById("contribution-option-other-1");
  if (contributionOptionOther1) { contributionOptionOther1.classList.remove('is-hidden'); }
}

function hideContributionDescription1() {
  var contributionDescription1 = document.getElementById("contribution-description-1");
  if (contributionDescription1) { contributionDescription1.classList.add('is-hidden'); }
}

function showContributionDescription1() {
  var contributionDescription1 = document.getElementById("contribution-description-1");
  if (contributionDescription1) { contributionDescription1.classList.remove('is-hidden'); }
}

function hideContributionOption2() {
  var contributionOption2 = document.getElementById("contribution-option-2");
  if (contributionOption2) { contributionOption2.classList.add('is-hidden'); }
}

function showContributionOption2() {
  var contributionOption2 = document.getElementById("contribution-option-2");
  if (contributionOption2) { contributionOption2.classList.remove('is-hidden'); }
}

function hideContributionOptionOther2() {
  var contributionOptionOther2 = document.getElementById("contribution-option-other-2");
  if (contributionOptionOther2) { contributionOptionOther2.classList.add('is-hidden'); }
}

function showContributionOptionOther2() {
  var contributionOptionOther2 = document.getElementById("contribution-option-other-2");
  if (contributionOptionOther2) { contributionOptionOther2.classList.remove('is-hidden'); }
}

function hideContributionDescription2() {
  var contributionDescription2 = document.getElementById("contribution-description-2");
  if (contributionDescription2) { contributionDescription2.classList.add('is-hidden'); }
}

function showContributionDescription2() {
  var contributionDescription2 = document.getElementById("contribution-description-2");
  if (contributionDescription2) { contributionDescription2.classList.remove('is-hidden'); }
}

function hideContributionOption3() {
  var contributionOption3 = document.getElementById("contribution-option-3");
  if (contributionOption3) { contributionOption3.classList.add('is-hidden'); }
}

function showContributionOption3() {
  var contributionOption3 = document.getElementById("contribution-option-3");
  if (contributionOption3) { contributionOption3.classList.remove('is-hidden'); }
}

function hideContributionOptionOther3() {
  var contributionOptionOther3 = document.getElementById("contribution-option-other-3");
  if (contributionOptionOther3) { contributionOptionOther3.classList.add('is-hidden'); }
}

function showContributionOptionOther3() {
  var contributionOptionOther3 = document.getElementById("contribution-option-other-3");
  if (contributionOptionOther3) { contributionOptionOther3.classList.remove('is-hidden'); }
}

function hideContributionDescription3() {
  var contributionDescription3 = document.getElementById("contribution-description-3");
  if (contributionDescription3) { contributionDescription3.classList.add('is-hidden'); }
}

function showContributionDescription3() {
  var contributionDescription3 = document.getElementById("contribution-description-3");
  if (contributionDescription3) { contributionDescription3.classList.remove('is-hidden'); }
}

function hideAddOption2Link() {
  var addOption2 = document.getElementById("add-option-2");
  if (addOption2) { addOption2.classList.add('is-hidden'); }
}

function showAddOption2Link() {
  var addOption2 = document.getElementById("add-option-2");
  if (addOption2) { addOption2.classList.remove('is-hidden'); }
}

function hideAddOption3Link() {
  var addOption3 = document.getElementById("add-option-3");
  if (addOption3) { addOption3.classList.add('is-hidden'); }
}

function showAddOption3Link() {
  var addOption3 = document.getElementById("add-option-3");
  if (addOption3) { addOption3.classList.remove('is-hidden'); }
}

function clearOption1Select() {
  var option1Select = document.getElementById("event_contribution_option_1")
  if (option1Select) { option1Select.selectedIndex = 0; }
}

function clearOption1Other() {
  var option1Other = document.getElementById("event_contribution_option_other_1");
  if (option1Other) { option1Other.value = ''; }
}
function clearOption1Description() {
  var option1Description = document.getElementById("event_contribution_description_1");
  if (option1Description) { option1Description.value = ''; }
}

function clearOption2Select() {
  var option2Select = document.getElementById("event_contribution_option_2")
  if (option2Select) { option2Select.selectedIndex = 0; }
}

function clearOption2Other() {
  var option2Other = document.getElementById("event_contribution_option_other_2");
  if (option2Other) { option2Other.value = ''; }
}
function clearOption2Description() {
  var option2Description = document.getElementById("event_contribution_description_2");
  if (option2Description) { option2Description.value = ''; }
}

function hideRemoveOption2Link() {
  var removeOption2 = document.getElementById("remove-option-2");
  if (removeOption2) { removeOption2.classList.add('is-hidden'); }
}

function showRemoveOption2Link() {
  var removeOption2 = document.getElementById("remove-option-2");
  if (removeOption2) { removeOption2.classList.remove('is-hidden'); }
}

function clearAndHideOption2() {
  clearOption2Select();
  clearOption2Other();
  clearOption2Description();
  hideContributionOption2();
  hideContributionOptionOther2();
  hideContributionDescription2();
  hideAddOption3Link();
  showAddOption2Link();
}

function clearOption3Select() {
  var option3Select = document.getElementById("event_contribution_option_3")
  if (option3Select) { option3Select.selectedIndex = 0; }
}

function clearOption3Other() {
  var option3Other = document.getElementById("event_contribution_option_other_3");
  if (option3Other) { option3Other.value = ''; }
}
function clearOption3Description() {
  var option3Description = document.getElementById("event_contribution_description_3");
  if (option3Description) { option3Description.value = ''; }
}

function clearAndHideOption3() {
  clearOption3Select();
  clearOption3Other();
  clearOption3Description();
  hideContributionOption3();
  hideContributionOptionOther3();
  hideContributionDescription3();
  // if AddOption2Link is not hidden, show 3
  var addOption2 = document.getElementById("add-option-2");
  if (addOption2.classList.contains('is-hidden')) {
    showAddOption3Link();
  }
}

var savingsPlaceholder = "Eg. We love her, but we’d rather she wasn’t still at home when she's 30!";
var experiencePlaceholder = "Eg. Our little piglet is desperate to visit Peppa Pig World. Every day she's truffleing!";
var lessonsPlaceholder = "Eg. He's decided to learn the tumpet, much to this parent's horror (sorry neighbours)!";
var groupGiftPlaceholder = "Eg. She really, really, really wants a bike! :)";


// Toggle 'Add Gift' button
function hideGiftButton() {
  var giftButtonField = document.getElementById("add-gift-button-field");
  if (giftButtonField) { giftButtonField.classList.add('is-hidden'); }
}

function showGiftButton() {
  var giftButtonField = document.getElementById("add-gift-button-field");
  if (giftButtonField) { giftButtonField.classList.remove('is-hidden'); }
}

function hideGiftSection() {
  var giftSelectField = document.getElementById("gift-section");
  if (giftSelectField) { giftSelectField.classList.add('is-hidden'); }
}

function showGiftSection() {
  var giftSelectField = document.getElementById("gift-section");
  if (giftSelectField) { giftSelectField.classList.remove('is-hidden'); }
}

function resetGiftOption1() {
  clearOption1Select();
  clearOption1Other();
  clearOption1Description();
  // hideContributionOption1();
  hideContributionOptionOther1();
  hideContributionDescription1();
  // hideAddOption2Link();
  showAddOption2Link();
  hideAddOption3Link();
}


function showRemove1LinkIfAllowed() {
  // check if 2 and 3 are hidden and if yes, show remove 1 link
  var contributionOption2 = document.getElementById("contribution-option-2");
  var contributionOption3 = document.getElementById("contribution-option-3");
  if (contributionOption2 && contributionOption3) {
    var hidden2 = contributionOption2.classList.contains('is-hidden');
    var hidden3 = contributionOption3.classList.contains('is-hidden');
    if (hidden2 && hidden3) { 
      var removeOption1 = document.getElementById("remove-option-1");
      if (removeOption1) {
        removeOption1.classList.remove('is-hidden');
      }
    }
  }
}

function hideOption1RemoveLink() {
  var removeOption1 = document.getElementById("remove-option-1");
  if (removeOption1) {
    removeOption1.classList.add('is-hidden');
  }
}


document.addEventListener("turbolinks:load", function () {

  var contributionOption1 = document.getElementById("event_contribution_option_1");
  var contributionDescription1 = document.getElementById("event_contribution_description_1");
  var contributionOther1 = document.getElementById("event_contribution_option_other_1");
  if (contributionOption1) {
    contributionOption1.addEventListener("change", function() {
      // if select has value (ie. not "please select...")
      selected = contributionOption1.value;
      // show description field
      if (selected === 'other') { 
        showContributionOptionOther1();
        showContributionDescription1();
        contributionOther1.placeholder = ""
        contributionDescription1.placeholder = "";
      } else if (selected) { 
        showContributionDescription1(); 
        hideContributionOptionOther1();
        if (selected === 'savings') {
          contributionDescription1.placeholder = savingsPlaceholder;
        } else if (selected === 'group_gift') {
          contributionDescription1.placeholder = groupGiftPlaceholder;
        } else if (selected === 'lessons') {
          contributionDescription1.placeholder = lessonsPlaceholder;
        } else if (selected === 'experience') {
          contributionDescription1.placeholder = experiencePlaceholder;
        }
       }
    });
  }

  var addOption2 = document.getElementById("add-option-2");
  if (addOption2) {
    addOption2.addEventListener("click", function(event) {
      event.preventDefault();
      hideAddOption2Link();
      showContributionOption2();
      var contributionOption3 = document.getElementById("contribution-option-3");
      if (contributionOption3.classList.contains('is-hidden')) {
        showAddOption3Link();
      }
      hideOption1RemoveLink();
    });
  }

  var contributionOption2 = document.getElementById("event_contribution_option_2");
  var contributionDescription2 = document.getElementById("event_contribution_description_2");
  var contributionOther2 = document.getElementById("event_contribution_option_other_2");
  if (contributionOption2) {
    contributionOption2.addEventListener("change", function() {
      // if select has value (ie. not "please select...")
      selected = contributionOption2.value;
      // show description field
      if (selected === 'other') { 
        showContributionOptionOther2();
        showContributionDescription2();
        contributionOther2.placeholder = ""
        contributionDescription2.placeholder = "";
      } else if (selected) { 
        showContributionDescription2(); 
        hideContributionOptionOther2();
        if (selected === 'savings') {
          contributionDescription2.placeholder = savingsPlaceholder;
        } else if (selected === 'group_gift') {
          contributionDescription2.placeholder = groupGiftPlaceholder;
        } else if (selected === 'lessons') {
          contributionDescription2.placeholder = lessonsPlaceholder;
        } else if (selected === 'experience') {
          contributionDescription2.placeholder = experiencePlaceholder;
        }
       }
    });
  }

  var addOption3 = document.getElementById("add-option-3");
  if (addOption3) {
    addOption3.addEventListener("click", function(event) {
      event.preventDefault();
      hideAddOption3Link();
      showContributionOption3();
      hideOption1RemoveLink();
    });
  }

  var contributionOption3 = document.getElementById("event_contribution_option_3");
  var contributionDescription3 = document.getElementById("event_contribution_description_3");
  var contributionOther3 = document.getElementById("event_contribution_option_other_3");
  if (contributionOption3) {
    contributionOption3.addEventListener("change", function() {
      // if select has value (ie. not "please select...")
      selected = contributionOption3.value;
      // show description field
      if (selected === 'other') { 
        showContributionOptionOther3();
        showContributionDescription3();
        contributionOther3.placeholder = ""
        contributionDescription3.placeholder = "";
      } else if (selected) { 
        showContributionDescription3(); 
        hideContributionOptionOther3();
        if (selected === 'savings') {
          contributionDescription3.placeholder = savingsPlaceholder;
        } else if (selected === 'group_gift') {
          contributionDescription3.placeholder = groupGiftPlaceholder;
        } else if (selected === 'lessons') {
          contributionDescription3.placeholder = lessonsPlaceholder;
        } else if (selected === 'experience') {
          contributionDescription3.placeholder = experiencePlaceholder;
        }
       }
    });
  }

  var removeOption1 = document.getElementById("remove-option-1");
  if (removeOption1) {
    removeOption1.addEventListener("click", function(event) {
      event.preventDefault();
      resetGiftOption1();
      hideGiftSection();
      showGiftButton();
    })
  }

  var removeOption2 = document.getElementById("remove-option-2");
  if (removeOption2) {
    removeOption2.addEventListener("click", function(event) {
      event.preventDefault();
      clearAndHideOption2();
      showRemove1LinkIfAllowed();
    })
  }

  var removeOption3 = document.getElementById("remove-option-3");
  if (removeOption3) {
    removeOption3.addEventListener("click", function(event) {
      event.preventDefault();
      clearAndHideOption3();
      showRemove1LinkIfAllowed();
    })
  }

  // Toggle 'Add Gift' button
  var giftButton = document.getElementById("add-gift-button");
  if (giftButton) {
    giftButton.addEventListener("click", function(event) {
      event.preventDefault();
      hideGiftButton();
      showGiftSection();
    });
  }

  var removeGiftLink = document.getElementById("remove-all-gifts");
  if (removeGiftLink) {
    removeGiftLink.addEventListener("click", function(event) {
      event.preventDefault();
      hideGiftSelectField();
      showGiftButton();
      resetGiftSelect();
    });
  }

});

