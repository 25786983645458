// import Swiper bundle with all modules installed
import swiper from 'swiper/bundle';
// import Swiper styles
import 'swiper/swiper-bundle.css';

document.addEventListener('turbolinks:load', function () {

  var swiperInviteBackgrounds = document.getElementById('swiper-invite-backgrounds');
  if (swiperInviteBackgrounds) {
    // grab initial slide numbers from data attributes
    var intialSlideMobile = Number(swiperInviteBackgrounds.dataset.initialSlideMobile);
    var intialSlideDesktop = Number(swiperInviteBackgrounds.dataset.initialSlideDesktop);
    // intialise swiper
    var swiperBackgrounds = new swiper ('.swiper-container', {
      slidesPerView: 3,
      // slidesPerColumn: 2,
      slidesPerGroup: 3,
      spaceBetween: 10,
      // for initialSlide, multiply answer by slidesPerGroup to make it work
      initialSlide: intialSlideMobile,
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        bulletActiveClass: 'swipper-pagination-dot-active',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-nav-next',
        prevEl: '.swiper-nav-prev',
      },
      breakpoints: {
        1024: {
          slidesPerView: 5,
          // slidesPerColumn: 2,
          slidesPerGroup: 5,
          spaceBetween: 10,
          initialSlide: intialSlideDesktop,
          // Navigation arrows
          // navigation: {
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev',
          // },
        },
      },
    })
  }

  var swiperReviews = document.getElementById('swiper-reviews');
  if (swiperReviews) {
    var swiperReviews = new swiper ('.swiper-container', {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination',
        bulletActiveClass: 'swipper-pagination-dot-active',
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-nav-next',
        prevEl: '.swiper-nav-prev',
      },
    
      breakpoints: {
        // 640: {
          //   slidesPerView: 2,
        //   spaceBetween: 20,
        // },
        // 768: {
          //   slidesPerView: 4,
        //   spaceBetween: 40,
        // },
        1024: {
          slidesPerView: 4,
          // slidesPerColumn: 2,
          slidesPerGroup: 4,
          spaceBetween: 10,
          initialSlide: 1,
          // pagination: false,
    
        },
      },
    })
  }

});