// Show and hide the Custom Event Type field 
// based on whether Event Type selected is 'Other'

function showCustomType() {
  var customType = document.getElementById("custom-type");
  if (customType) { customType.classList.remove('is-hidden'); }
}


function hideCustomType() {
  var customType = document.getElementById("custom-type");
  if (customType) { customType.classList.add('is-hidden'); }
}

document.addEventListener("turbolinks:load", function () {

  var eventType = document.getElementById("event_event_type");
  if (eventType) {
    eventType.addEventListener("change", function() {
      selected = eventType.value;
      if (selected === 'other') {
        showCustomType();
      } else if (selected) {
        hideCustomType();
      }
    });
  }

});