// hide tab content
// remove active style for tab
// show tab content
// set active style for tab

document.addEventListener("turbolinks:load", () => {

  var yourGiftAndRsvpTabLink = document.getElementById("your-gift-and-rsvp-tab-link");
  var rsvpOnlyTabLink = document.getElementById("rsvp-only-tab-link");

  var yourGiftAndRsvpTab = document.getElementById("your-gift-and-rsvp-tab");
  var rsvpOnlyTab = document.getElementById("rsvp-only-tab");

  var yourGiftAndRsvpForm = document.getElementById("payment-form-wrapper");
  var rsvpOnlyForm = document.getElementById("rsvp-only-form-wrapper");

  if (yourGiftAndRsvpTabLink && rsvpOnlyTabLink) {

    rsvpOnlyTabLink.addEventListener("click", function(event) {
      event.preventDefault();
      yourGiftAndRsvpForm.classList.add('is-hidden');
      yourGiftAndRsvpTab.classList.remove('is-active');
      rsvpOnlyTab.classList.add('is-active');
      rsvpOnlyForm.classList.remove('is-hidden');
    });
    
    yourGiftAndRsvpTabLink.addEventListener("click", function(event) {
      event.preventDefault();
      rsvpOnlyForm.classList.add('is-hidden');
      rsvpOnlyTab.classList.remove('is-active');
      yourGiftAndRsvpTab.classList.add('is-active');
      yourGiftAndRsvpForm.classList.remove('is-hidden');
    });

  }

});