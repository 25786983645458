// Tippy.js is a highly customizable tooltip and popover library powered by Popper.js.
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

document.addEventListener("turbolinks:load", () => {
  tippy('.has-tooltip', {
    trigger: 'click',
    placement: 'bottom',
    onShow(instance) {
      setTimeout(() => {
        instance.hide();
      }, 3000); // 3000 = 3 seconds
    }
  });
  tippy('.has-tooltip-hover', {

  });


});